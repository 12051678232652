import * as React from "react";
import AdminLayout from "../../components/AdminLayout";
import UserView from "../../components/UserView";
import UserEdit from "../../components/UserEdit";

import { getSuscriptor } from "../../api/users";
// import Spinner from "../../components/Spinner";

const UserPage = ({ location }) => {
  const [suscriptor, setSuscriptor] = React.useState();
  const [mode, setMode] = React.useState("view");

  const changeMode = () => {
    setMode(mode === "view" ? "edit" : "view");
  };
  React.useEffect(() => {
    getSuscriptor(location.pathname.split("/")[2]).then((data) => {
      setSuscriptor(data);
      // setLoading(true)
    });
  }, []);

  React.useEffect(() => {
    getSuscriptor(location.pathname.split("/")[2]).then((data) => {
      setSuscriptor(data);
    });
  }, [mode]);

  return (
    <AdminLayout>
      {suscriptor ? (
        <div className="flex flex-col break-words ">
          <div className="flex flex-col md:flex-row mb-5">
            <div className="md:w-1/4  mb-6 md:mb-0 w-full py-12 rounded-2xl bg-white">
              <div className="flex flex-col p-2">
                <h3 className="text-sc-black text-2xl font-bold text-center">
                  Suscriptor
                </h3>
                <div className="w-9/12 m-auto">
                  <img
                    className="rounded-full"
                    src={
                      suscriptor.avatar !== ""
                        ? suscriptor.avatar
                        : "https://freepikpsd.com/file/2019/10/default-profile-image-png-1-Transparent-Images.png"
                    }
                    alt="avatar"
                  />
                </div>
                <h3 className=" mt-8 text-sc-black text-3xl font-bold text-center">
                  {/* Pedro Ramirez */}
                  {`${suscriptor.nombre} ${suscriptor.apellido}`}
                </h3>
                <p className="font-semibold">
                  Fecha de inicio: {suscriptor.createdAt}
                </p>
                <p className="mb-0 font-semibold">Tipo de cuenta:</p>
                {/* hola */}
                <p className="font-semibold">Personal/Empresarial</p>
              </div>
            </div>
            <div className="md:w-3/4 w-full flex rounded-2xl md:m-1 bg-white py-6 px-5">
              {mode === "view" ? (
                <UserView
                  suscriptor={suscriptor}
                  changeMode={changeMode}
                  mode={mode}
                />
              ) : null}
              {mode === "edit" ? (
                <UserEdit
                  changeMode={changeMode}
                  suscriptor={suscriptor}
                  mode={mode}
                  setMode={setMode}
                />
              ) : null}
            </div>
          </div>
          <div className="flex md:justify-end mb-5">
            <div className="w-full md:w-3/4  py-6 px-5 rounded-2xl bg-white">
              <h1 className="text-left text-2xl w-full">Datos personales</h1>
              <div>
                <div className="columns-2">
                  <div className="text-left px-1 py-2 font-semibold">
                    <p>Fecha de registro: {suscriptor.createdAt}</p>
                  </div>
                  {/* <div className="text-left px-1 py-2 font-semibold">
                    <p>Fecha de ultimo pago:2</p>
                  </div>
                  <div className="text-left px-1 py-2 font-semibold">
                    <p>Fecha de ultimo inicio de sesión:3</p>
                  </div>
                  <div className="text-left px-1 py-2 font-semibold">
                    <p>Fecha de cancelación:4</p>
                  </div>
                  <div className="text-left px-1 py-2 font-semibold">
                    <p>Fecha de cancelación:5</p>
                  </div> */}
                  <div className="text-left px-1 py-2 font-semibold">
                    <p>Fecha de registro: {suscriptor.createdAt}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex md:justify-end">
            <div className="w-full md:w-3/4  py-6 px-5 rounded-2xl bg-white">
              <h1 className="text-left text-2xl w-full">Hashtags que sigue</h1>
              <div>
                <div className="text-left px-1 py-2 font-semibold">
                  <p>Este usuario no sigue ningún hasgtag.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : // <Spinner />
      null}
    </AdminLayout>
  );
};
export default UserPage;
