import * as React from "react";
import SCButton from "../components/SCButton";

const UserView = ({ suscriptor, changeMode, mode }) => {
  return (
    <div className="w-full flex flex-col">
      <div className="flex  flex-col md:flex-row w-full">
        <div className="flex w-full md:w-1/2 flex-col">
          <h1 className="text-left text-2xl">Datos personales</h1>
          <div className="my-2 border-b border-black text-left px-4 py-2">
            <p className="m-0 text-xs text-gray-500">Correo electrónico</p>
            <p className="m-0  font-semibold">{suscriptor.email}</p>
          </div>
          <div className="my-2 border-b border-black text-left px-4 py-2">
            <p className="m-0 text-xs text-gray-500">Nombre</p>
            <p className="m-0  font-semibold">{suscriptor.nombre}</p>
          </div>
          <div className="my-2 border-b border-black text-left px-4 py-2">
            <p className="m-0 text-xs text-gray-500">Apellido</p>
            <p className="m-0  font-semibold">{suscriptor.apellido}</p>
          </div>
          <div className="my-2 border-b border-black text-left px-4 py-2">
            <p className="m-0 text-xs text-gray-500">Label</p>
            <p className="m-0  font-semibold">nickname</p>
          </div>
          <div className="my-2 border-b border-black text-left px-4 py-2">
            <p className="m-0 text-xs text-gray-500">Fecha de nacimiento</p>
            <p className="m-0  font-semibold">{suscriptor.fechaNacimiento}</p>
          </div>
          <div className="my-2 border-b border-black text-left px-4 py-2">
            <p className="m-0 text-xs text-gray-500">Sexo</p>
            <p className="m-0  font-semibold">{suscriptor.sexo}</p>
          </div>
          <div className="my-2 border-b border-black text-left px-4 py-2">
            <p className="m-0 text-xs text-gray-500">Telefono</p>
            <p className="m-0  font-semibold">{suscriptor.telefono}</p>
          </div>
        </div>
        <div className="flex w-full md:w-1/2 flex-col md:ml-5">
          <h1 className="text-left text-2xl">Datos de salud</h1>
          <div className="my-2 border-b border-black text-left px-4 py-2">
            <p className="m-0 text-xs text-gray-500">Peso</p>
            <p className="m-0  font-semibold">{suscriptor.peso} kg</p>
          </div>
          <div className="my-2 border-b border-black text-left px-4 py-2">
            <p className="m-0 text-xs text-gray-500">Estatura</p>
            <p className="m-0  font-semibold">{suscriptor.estatura} m</p>
          </div>
          {/* <div className="my-2 border-b border-black text-left px-4 py-2">
                    <p className="m-0 text-xs text-gray-500">Edad</p>
                    <p className="m-0  font-semibold">{suscriptor.edad}</p>
                  </div> */}
        </div>
      </div>
      <div className="m-4 flex justify-end ">
        <div className="w-32">
          <SCButton onClick={changeMode}>
            {mode === "view" ? "Editar" : "Guardar"}
          </SCButton>
        </div>
      </div>
    </div>
  );
};

export default UserView;
